'use strict';

import store from 'client/store';

import categoryLib from 'client/lib/category'
import rankingLib from 'client/lib/ranking.js'
import rounds from 'client/lib/rounds'

import sessionMain from '/lib/session'

import moment from 'moment';

import clone from "lodash/clone";
import concat from "lodash/concat";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import intersection from "lodash/intersection"
import map from "lodash/map";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import union from "lodash/union";
import unionBy from "lodash/unionBy";

export default {

  getSessionLabel: function(session) {
    return moment(session.date).format('DD-MM') + ' ' + session.name;
  },

  getSetLabel: function() {
    const rotationType = store.state.eventDiscipline.discipline.rotationType
    return sessionMain.getSetLabel(rotationType)
  },

  getSessionRotationType: function(sessionId) {
    const session = find(store.state.sessions.items, s => s.id === sessionId)
    return this.getSessionRotationTypeFull(session)
  },

  getSessionRotationTypeFull: function(session) {
    let discipline = store.state.eventDiscipline.discipline
    if (! discipline) {
      const eventDiscipline = store.state.eventDisciplines.items.find(i => i.id === session.eventDisciplineId)
      discipline = store.state.disciplines.items.find(i => i.id === eventDiscipline.disciplineId)
    }

    let rotationType = discipline.rotationType
    return rotationType
  },

  getSetRotations: function(session, set) {
    const rotationType = this.getSessionRotationType(session.id)
    return sessionMain.getSetRotations(session, set, rotationType)
  },

  getSessionRotationTypeSecondary: function(session) {
    const discipline = store.state.eventDiscipline.discipline
    const mixedRotationTypes = discipline.config.mixedRotationTypes || false

    let rotationType = null

    if (mixedRotationTypes && session) {

      if (session.exerciseTypes?.length) {
        const exerciseTypes = store.state.eventDiscipline.exerciseTypes

        let mixed = false
        session.exerciseTypes.forEach(etId => {
          const et = exerciseTypes.find(type => type.id === etId)

          const ert = et.config.rotationType || rotationType

          if (ert === 'mixed') {
            mixed = true
          }
        })

        if (mixed) {
          rotationType = 'mixed'
        } else {
          rotationType = discipline.rotationType
        }
      }
    }

    return rotationType
  },

  /** get exercise types that can be included in a session based on categories of the session */
  getSessionExerciseTypes(sessionId) {
    const session = store.state.sessions.items.find(s => s.id === sessionId)

    const categories = this.getCategories(session)

    let exerciseTypes = []
    categories.forEach(category => {
      const catExerciseTypes = categoryLib.getFormatExerciseTypes(category.id, session.roundIndex)
      exerciseTypes = exerciseTypes.concat(catExerciseTypes)
    })

    return Array.from(new Set(exerciseTypes))
  },

  /** get exerciseTypes to be executed by category in session (i.e. filtered by session exercise types) */
  getSessionCategoryExerciseTypes(sessionId, categoryId) {
    const session = store.state.sessions.items.find(s => s.id === sessionId)

    let exerciseTypes = categoryLib.getCategoryExerciseTypes(categoryId, session.roundIndex)

    if (session.exerciseTypes?.length) {
      exerciseTypes = exerciseTypes.filter(et => session.exerciseTypes.includes(et))
    }

    return exerciseTypes
  },

  getSessionEffectiveExerciseTypes(session) {
    return session.exerciseTypes?.length ? session.exerciseTypes : sessionMain.getSessionExerciseTypes(session, store.state.categories.items)
  },

  getCategories: function(session) {
    return sessionMain.getSessionCategories(session, store.state.categories.items)
  },

  getSessionTimes: function(session, full = true) {
    const rotationType = this.getSessionRotationTypeFull(session)
    return sessionMain.getSessionTimes(session, rotationType, full)
  },

  /** Get participations of a session, not including team participations */
  getParticipations: function(session, set = 0, categoryId = null) {
    return filter(store.state.participations.items, item => {
      if (item.participantType === 'team') {
        return false
      }
      if (categoryId && categoryId !== item.categoryId) {
        return false
      }

      const category = find(session.categories,
          cat => cat.categoryId === item.categoryId && (set === 0 || set === cat.set))
      if (category) {
        const partRound = find(item.rounds, pRound => pRound.roundIndex === session.roundIndex )
        if (! partRound) {
          return false
        }

        // filter by sub division
        if (category.subDivision !== parseInt(partRound.subDivision)) return false

        // filter based on exercise selection
        if (session.exerciseTypes?.length && partRound.exerciseSelection?.length) {
          const partExercises = partRound.exerciseSelection.map(e => e.exerciseTypeId)
          const match = intersection(session.exerciseTypes, partExercises)
          if (! match.length) return false
        }

        return true
      }

      return false
    });
  },

  getParticipationsWithExercises: function(session, catFilter = null) {
    let participations = this.getParticipations(session);

    return reduce(participations, (results, item) => {
      const category = find(session.categories, cat => {
        return cat.categoryId === item.categoryId;
      });

      if (category) {
        const partRound = find(item.rounds, pRound => {
          return pRound.roundIndex === session.roundIndex
        });
        if (partRound) {
          if (category.subDivision === parseInt(partRound.subDivision)) {
            results.push({
              part: item,
              status: partRound.status,
              exercises: reduce(category.exercises, (result, item) => {
                if (item.set > 0) {
                  let addExercise = true;
                  let options = {};

                  if (partRound.exerciseSelection?.length) {
                    const selection = find(partRound.exerciseSelection, item2 => {
                      return item.exerciseTypeId === item2.exerciseTypeId;
                    });
                    if (! selection) {
                      addExercise = false;
                    } else {
                      options = selection.options;
                    }
                  }

                  if (catFilter && catFilter[category.categoryId] !== item.set && catFilter[category.categoryId] !== -1) {
                    addExercise = false;
                  }

                  if (addExercise) {
                    result[item.exerciseTypeId] = {
                      set: item.set,
                      options: options,
                    };
                  }
                }
                return result;
              }, {}),
            });
          }
        }
      }
      return results;
    }, []);
  },

  /** Get team participations of a session */
  getTeamParticipations: function(session, set = 0) {
    return filter(store.state.participations.items, item => {
      if (item.participantType !== 'team') {
        return false;
      }

      const category = find(session.categories,
          cat => cat.categoryId === item.categoryId && (set === 0 || set === cat.set))
      if (category) {
        const partRound = find(item.rounds, pRound => pRound.roundIndex === session.roundIndex)
        if (! partRound) {
          return false;
        }
        return category.subDivision === parseInt(partRound.subDivision)
      }

      return false;
    });
  },

  hasAwards: function(category, roundIndex, type) {
    const cRound = find(category.rounds, item => item.roundIndex === roundIndex)

    let attribute = 'singleAwards';
    if (type === 'TEAM-AA') {
      attribute = 'teamAwards';
    }

    return (cRound && cRound[attribute] && cRound[attribute].length);
  },

  getRankingTypes: function(session) {
    const exerciseTypes = store.getters.sessionExerciseTypes(session)

    let result = []
    let count = 0

    const categories = this.getCategories(session)
    const aaAwards = reduce(categories, (flag, category) => {
      return flag || this.hasAwards(category, session.roundIndex, 'AA')
    }, false)

    const teamAwards = reduce(categories, (flag, category) => {
      return flag || this.hasAwards(category, session.roundIndex, 'TEAM-AA')
    }, false)

    const round = rounds.getRound(session.roundIndex)

    forEach(session.rankingTypes, rankingType => {
      let subTypes = []

      switch (rankingType.rankingType) {
        case 'AA':
          if (aaAwards) {
            subTypes.push({
              subType: 'awards',
            })
          }
          break
        case 'TEAM-AA':
          if (teamAwards) {
            subTypes.push({
              subType: 'awards',
            })
          }
          break
      }

      if (rankingType.regular) {
        subTypes.push({
          subType: 'regular',
        })
      }
      if (rankingType.open) {
        subTypes.push({
          subType: 'open',
        })
      }
      if (rankingType.regional) {
        forEach(rankingType.regions, region => {
          subTypes.push({
            subType: 'regional',
            region: region,
          })
        })
      }

      if (subTypes.length) {
        if (rankingType.rankingType !== 'EVENTS' && rankingType.rankingType !== 'TEAM-EVENTS') {
          count += subTypes.length
          result.push({
            type: rankingType.rankingType,
            subTypes,
          })
        } else {
          result = concat(result, map(exerciseTypes, exerciseType => {
            count += subTypes.length
            return {
              type: rankingType.rankingType,
              exerciseTypeId: exerciseType.id,
              exerciseTypeIndex: exerciseType.index,
              subTypes: clone(subTypes),
            }
          }))
        }
      }
    })

    let hasGroups = reduce(categories, (res, category) => { return res || category.participantType === 'group'}, false)
    let hasTeams = reduce(result, (res, type) => res || type.type === 'TEAM-AA' ||type.type === 'TEAM-EVENTS', false)

    const rankingTypes = [
      {
        round,
        count,
        hasGroups,
        hasTeams,
        rankingTypes: result,
      },
    ]

    return {rankingTypes, categories}
  },

  getRankingSelections: function(rankingTypes, category) {
    const types = []

    forEach(rankingTypes, roundTypes => {
      forEach(roundTypes.rankingTypes, type => {
        let ranking = null
        forEach(type.subTypes, subType => {
          let selected = true

          if (subType.subType === 'awards') {
            if (this.hasAwards(category, roundTypes.round.index, type.type)) {
              ranking = rankingLib.getRanking(category.id, roundTypes.round.index, type.type, 'open', subType.region)
              if (!ranking) {
                ranking = rankingLib.getRanking(category.id, roundTypes.round.index, type.type, 'regular', subType.region)
              }
            }
          } else {
            ranking = rankingLib.getRanking(category.id, roundTypes.round.index, type.type, subType.subType, subType.region, type.exerciseTypeId)

            if (category.rankingPublication !== 'full') {
              selected = false
            }
          }

          const empty = ranking ? ranking.items.length === 0 : true
          types.push({
            roundIndex: roundTypes.round.index,
            type: type.type,
            subType: subType.subType,
            exerciseTypeId: type.exerciseTypeId,
            region: subType.region,
            rankingId: ranking ? ranking.id : null,
            empty: empty,
            itemCount: ranking?.items.length,
            selected: !empty && selected,
          })
        })
      })
    })

    return {
      category,
      types,
    }
  },

  mergeRankingTypes: function(rankingTypes1, rankingTypes2) {
    const rds = sortBy(rounds.getRounds(), 'index')
    const result = []

    rds.forEach(round => {
      const types1 = rankingTypes1.find(i => i.round.index === round.index)
      const types2 = rankingTypes2.find(i => i.round.index === round.index)

      if (types1) {
        if (types2) {
          const rankingTypes = this.mergeRoundRankingTypes(types1.rankingTypes, types2.rankingTypes)
          result.push({
            round,
            count: rankingTypes.reduce((acc, type) => acc + type.subTypes.length , 0),
            rankingTypes,
            hasGroups: types1.hasGroups || types2.hasGroups,
            hasTeams: types1.hasTeams || types2.hasTeams,
          })
        } else {
          result.push(types1)
        }
      } else if (types2) {
        result.push(types2)
      }
    })

    return result
  },

  mergeRoundRankingTypes: function(rankingTypes1, rankingTypes2) {
    let types = []

    const findType = function(rankingTypes, type, exerciseTypeId = null) {
      return find(rankingTypes,
          item => item.type === type && (exerciseTypeId ? exerciseTypeId === item.exerciseTypeId : true))
    }

    const filterEvents = function(types, rankingType) {
      let exerciseTypes = []
      forEach(types, item => {
        if (item.type === rankingType) {
          exerciseTypes.push({
            exerciseTypeId: item.exerciseTypeId,
            index: item.exerciseTypeIndex,
          });
        }
      })
      return exerciseTypes
    }

    // AA
    let type1 = findType(rankingTypes1, 'AA')
    let type2 = findType(rankingTypes2, 'AA')

    types.push(this.mergeMainTypes(type1, type2))

    // TEAM-AA
    type1 = findType(rankingTypes1, 'TEAM-AA')
    type2 = findType(rankingTypes2, 'TEAM-AA')

    types.push(this.mergeMainTypes(type1, type2))

    // Events:
    let events = sortBy(unionBy(
      filterEvents(rankingTypes1, 'EVENTS'),
      filterEvents(rankingTypes2, 'EVENTS', 'exerciseTypeId'), 'index'))
    forEach(events, event => {
      type1 = findType(rankingTypes1, 'EVENTS', event.exerciseTypeId)
      type2 = findType(rankingTypes2, 'EVENTS', event.exerciseTypeId)

      types.push(this.mergeMainTypes(type1, type2))
    })

    // TEAM-EVENTS
    events = sortBy(unionBy(
      filterEvents(rankingTypes1, 'TEAM-EVENTS'),
      filterEvents(rankingTypes2, 'TEAM-EVENTS', 'exerciseTypeId'), 'index'))
    forEach(events, event => {
      type1 = findType(rankingTypes1, 'TEAM-EVENTS', event.exerciseTypeId)
      type2 = findType(rankingTypes2, 'TEAM-EVENTS', event.exerciseTypeId)

      types.push(this.mergeMainTypes(type1, type2))
    })

    return types.filter(i => !! i)
  },

  mergeMainTypes: function(type1, type2) {
    let type = null
    if (type1) {
      if (type2) {
        type = {
          type: type1.type,
          exerciseTypeId: type1.exerciseTypeId,
          exerciseTypeIndex: type1.exerciseTypeIndex,
          subTypes: this.mergeSubTypes(type1.subTypes, type2.subTypes),
        }
      } else {
        type = type1
      }
    } else if (type2) {
      type = type2
    }

    return type
  },

  mergeSubTypes: function(subTypes1, subTypes2) {
    let subTypes = [];

    const hasType = function(subTypes, subType) {
      const res = find(subTypes, item => {
        return item.subType === subType;
      });
      return res !== undefined;
    };
    const filterRegions = function(subTypes) {
      let regions = [];
      forEach(subTypes, item => {
        if (item.subType === 'regional') {
          regions.push(item.region);
        }
      });
      return regions;
    };

    if (hasType(subTypes1, 'awards') || hasType(subTypes2, 'awards')) {
      subTypes.push({
        subType: 'awards',
      });
    }
    if (hasType(subTypes1, 'regular') || hasType(subTypes2, 'regular')) {
      subTypes.push({
        subType: 'regular',
      });
    }
    if (hasType(subTypes1, 'open') || hasType(subTypes2, 'open')) {
      subTypes.push({
        subType: 'open',
      });
    }

    let regions = union(filterRegions(subTypes1), filterRegions(subTypes2));
    forEach(regions, region => {
      subTypes.push({
        subType: 'regional',
        region: region,
      });
    });

    return subTypes
  },

  getSelectionTypes: function(session) {
    const exerciseTypes = store.getters.sessionExerciseTypes(session)

    let selectionTypes = []

    const round = rounds.getRound(session.roundIndex)

    forEach(session.rankingTypes, rankingType => {
      const nextRound = rounds.nextRound(round, rankingType.rankingType)

      if (nextRound) {
        if (rankingType.rankingType !== 'EVENTS' && rankingType.rankingType !== 'TEAM-EVENTS') {
          selectionTypes.push({
            type: rankingType.rankingType,
            nextRound,
          })
        } else {
          selectionTypes = concat(selectionTypes, map(exerciseTypes, exerciseType => {
            return {
              type: rankingType.rankingType,
              exerciseTypeId: exerciseType.id,
              exerciseTypeIndex: exerciseType.index,
              nextRound,
            }
          }))
        }
      }
    })

    return selectionTypes
  },

  getCategorySelections: function(round, rankingTypes, category) {
    const types = []
    forEach(rankingTypes, type => {
      const nextRound = rounds.nextRound(round, type.type)
      if (nextRound) {
        types.push({
          roundIndex: round.index,
          type: type.type,
          exerciseTypeId: type.exerciseTypeId,
        })
      }
      else {
        types.push({})
      }
    })

    return {
      category,
      types,
    }
  },

}
